@import '~@skyscanner/backpack-web/bpk-mixins/index.scss';

.HotelCard {
  position: relative;
  margin-bottom: .5rem;

  &__Gallery {
    height: 120px;
    width: 100%;
    background: gray;
    border-radius: $bpk-border-radius-md $bpk-border-radius-md 0 0;
    position: relative;
  }

  &__CardContent {
    padding: bpk-spacing-base();

    &__Headline {
      display: flex;
      justify-content: space-between;
    }

    &__PriceContainer {
      display: flex;
      justify-content: flex-end;
    }

    &__CardLink {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: $bpk-border-radius-md;

      a::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: $bpk-border-radius-md;
      }
    }
  }

  &__SaveButton {
    position: absolute;
    top: bpk-spacing-base();
    right: bpk-spacing-base();
    z-index: 10;
    border-radius: 50%;
  }

  &__CardLink:focus-within, &__SaveButton:focus-within {
    outline: .125rem solid #0062e3;
    outline-offset: .125rem;
  }
}



