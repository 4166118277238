body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust:exact !important;
  print-color-adjust:exact !important;
}

html, body, #root {
  height: 100%;
}

.main-content {
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.desktop-container {
  max-width: 76.5rem;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

#office_locations {
  display: grid;
  gap: 0.75rem;
}

.dialog-buttons {
  display: grid;
  gap: .75rem;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

h2 {
  margin-bottom: 0.5rem!important;
}

.icon-grid {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

/*form {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  gap: 1rem;*/
/*}*/