/*
 * Backpack - Skyscanner's Design System
 *
 * Copyright 2016 Skyscanner Ltd
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import '~@skyscanner/bpk-foundations-web/tokens/base.default.scss';

@keyframes heart-beat {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }

  10% {
    transform: translate(-50%, -50%) scale(1.4);
  }

  20% {
    transform: translate(-50%, -50%) scale(1.2);
  }

  30% {
    transform: translate(-50%, -50%) scale(1.3);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.25);
  }

  70% {
    transform: translate(-50%, -50%) scale(1.25);
  }
}

.bpk-save-button {
  position: relative;
  width: bpk-spacing-xxl();
  height: bpk-spacing-xxl();
  padding: bpk-spacing-md();
  border: 0;
  border-radius: 50%;
  background-color: transparent;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
  }

  &__heartOutlineIcon {
    z-index: 1;
    opacity: 1;
    composes: bpk-save-button__icon;

    &[data-show='false'] {
      opacity: 0;
    }
  }

  &__heartIcon {
    z-index: 2;
    opacity: 0;
    composes: bpk-save-button__icon;

    &[data-show='true'] {
      opacity: 1;
    }

    &--clicked {
      animation: heart-beat 1s 0s 1 ease-out;
      animation-fill-mode: forwards;
    }
  }

  &__small {
    width: bpk-spacing-xl();
    height: bpk-spacing-xl();
    min-height: bpk-spacing-xl();
  }

  &__contained {
    background-color: $bpk-private-card-button-contained-color;
  }
}