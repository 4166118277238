@import '~@skyscanner/bpk-foundations-web/tokens/base.default.scss';

.HotelCard {
  position: relative;
  margin-bottom: .5rem;
}

.SaveButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
}

.Gallery {
  height: 120px;
  width: 100%;
  background: gray;
  border-radius: .75rem .75rem 0 0;
  position: relative;
}

.Gallery:focus {
  outline: 2px solid $bpk-core-accent-day;
}

.CardContent {
  padding: 1rem;
}

.Headline {
  display: flex;
  justify-content: space-between;
}

.PriceContainer {
  display: flex;
  justify-content: flex-end;
}

.CardLink {
  border-radius: .75rem;
}

.CardLink {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  a::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: .75rem;
  }
}

.SaveButton {
  border-radius: 50%;
}

.CardLink:focus-within, .SaveButton:focus-within {
  outline: .125rem solid #0062e3;
  outline-offset: .125rem;
}

.rating {
  position: relative;
  z-index: 10;
}