@import '~@skyscanner/bpk-foundations-web/tokens/base.default.scss';

.FlightCard {
  &__CardLink {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: $bpk-border-radius-md;

    a::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: $bpk-border-radius-md;
    }
  }
}

.FlightLegContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
}

.FlightLeg {
  display: grid;
  gap: bpk-spacing-base();
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: bpk-spacing-base();

  &__AirlineLogo {
    width: 100%;
    background-color: purple;
    border-radius: $bpk-border-radius-md;
  }

  &__Outbound {
    display: flex;
    flex-direction: column;
    text-align: end;
    z-index: 10;
  }

  &__Inbound {
    display: flex;
    flex-direction: column;
    text-align: start;
    z-index: 10;
  }

  &__Duration {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.FullWidth {
  width: 100%;
  margin-bottom: bpk-spacing-base();
}